.canvas-draw {
  width: 100%;
  margin-bottom: var(--margin-block);
}

/* #mathscript-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

.canvas-div {
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 0.75);
  padding-inline: var(--padding-inline-ten-percent);
  padding-block: var(--gap);
  width: 100%;
  margin-top: 100px;
  /* background-image: url("../files/universe.jpeg");
  background-color: rgb(132, 132, 132);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  color: var(--white);
  /*   background-color: var(--darkgrey);
 */
  animation: moveSpace 50s linear infinite alternate-reverse;
  box-shadow: var(--drop-shadow);

  /*   CHANGES BELOW 
 */
  height: fit-content;
  position: relative;
  top: -140px;
}

.canvas-div::before {
  content: "Mathscript. Mjesto gdje počinje digitalizacija.";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(216, 32, 32, 0.6);

  z-index: 99;
  padding: 0.75rem 5%;
  font-weight: bold;
  text-shadow: 0 0 20px rgb(116, 62, 62);

  /*   border-top: white 2px solid;
 */
  text-align: center;
  font-size: clamp(var(--clamp-red-text));

  /*   font-family: var(--cursive-font); */
  /* background-image: linear-gradient(
    to bottom,
    transparent,
    rgba(216, 32, 32, 1)
  ); */
}

.canvas-div .videopl {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.videodiv {
  position: absolute;
  inset: 0;
  /*   background-color: rgba(203, 101, 101, 0);
 */
  mix-blend-mode: normal;
  opacity: 0;
}

@keyframes moveSpace {
  0% {
    background-position: left top;
  }

  100% {
    background-position: right bottom;
  }
}

.canvas-div > * {
  pointer-events: all;
  z-index: 11;
}

.canvas-div h1 {
  font-size: clamp(var(--clamp-big-text));
  margin-top: 80px;
  background-color: rgba(216, 32, 32, 0);
  padding: 0rem 0.5rem;
  border-radius: 10px;
  width: fit-content;
  /*   text-shadow: 0 0 20px black;
 */
  color: var(--black);
  word-spacing: 5px;
  text-transform: uppercase;
  letter-spacing: -1px;
}

.canvas-div h3 {
  font-size: clamp(var(--clamp-medium-text));

  background-color: rgba(216, 32, 32, 0);

  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  text-shadow: 0 0 20px black;
}

.canvas-div p {
  font-size: clamp(var(--clamp-medium-header-text));
  font-weight: bold;
  letter-spacing: 2px;
  word-spacing: 2px;

  /*   background-color: rgba(0, 0, 0, 0.65);
 */
  /*   text-shadow: 0 0 20px black;
 */
  padding: 0rem 0.5rem;
  border-radius: 10px;
  color: var(--red);
  width: min(90%, 950px);
}

.world {
  height: 700px;
  width: 100%;
  padding-inline: var(--padding-inline-five-percent);
  background-image: url("../files/world.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -20px;
  animation: moveBackground 8s ease-out alternate infinite;
  pointer-events: none;
}

@keyframes moveBackground {
  0% {
    transform: scale(0.94);
  }

  100% {
    transform: scale(1);
  }
}

.motto-canvas {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 1rem var(--padding-inline-ten-percent);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--red);
  color: var(--white);
  font-size: clamp(var(--clamp-small-text));
  font-weight: bold;
  filter: drop-shadow(var(--drop-shadow-lighter));
  gap: var(--gap);
}

.motto-canvas::before {
  content: attr(beforeName);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkgrey);
  cursor: pointer;
  /*   background-image: linear-gradient(45deg, var(--gradient));
 */

  padding: 1rem var(--padding-inline-ten-percent);
  transform: scale(0);
  transform-origin: bottom;
  transition: all 0.4s ease-in-out;
}

.motto-canvas:hover::before {
  transform: scale(1);
}

.circles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 140px));
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 80px;
  width: 100%;
  place-content: space-evenly;
  border-block: 3px solid var(--red);
  padding-block: 1rem;
}

.circle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  transition: var(--transition-short);
}

.circle-icon {
  color: var(--black);
  font-size: 50px;
  transition: var(--transition-short);
}

.circle p {
  text-align: center;
  color: var(--black);
  transition: var(--transition-short);
  font-size: clamp(var(--clamp-small-text));
}

.circle:hover p,
.circle:hover .circle-icon {
  color: var(--red);
}

.card-overlay {
  padding-inline: min(180px, 10%);
  display: grid;
  gap: 10px;
}

.card-overlay > h3 {
  font-size: clamp(var(--clamp-medium-text));
}

.card-overlay p {
  width: min(900px, 100%);
  color: var(--red);
  font-weight: bold;
  margin-bottom: 0;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 120px;
  margin-bottom: 120px;
  perspective: 30px;
  margin-top: 80px;
}

.card {
  flex: min(380px, 100%);
  height: 425px;
  height: min(500px, 60vh);
  display: grid;
  place-content: center;
  place-items: center;

  color: var(--white);
  text-align: center;
  filter: drop-shadow(0 0 0.5rem rgb(46, 46, 46));
  position: relative;

  transition: var(--transition-middle);
  transform-style: preserve-3d;
}

.back,
.front {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: var(--transition-middle);
}

.front {
  background-color: rgb(150, 70, 70);
  background-image: url("../files/leaves2.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: overlay;
  backface-visibility: hidden;

  display: grid;
  place-content: start;
  gap: 30px;
}

.front h3 {
  background-color: rgba(255, 255, 255, 0.65);
  width: 100%;
  padding: 1rem;
  color: var(--black);
  font-size: clamp(var(--clamp-before-text));
  font-family: var(--cursive-font);
  text-transform: lowercase;
}

.front p {
  padding-inline: 2rem;
  width: 75%;
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--black);
  margin-inline: auto;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  line-height: 1.3;
  font-weight: normal;
}

.back {
  position: absolute;
  backface-visibility: hidden;

  background-image: url("../files/leavesimg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(237, 234, 220);
  background-blend-mode: multiply;
  transform: rotateY(180deg);
}

.back p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.85);
  color: var(--black);
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  font-weight: bold;
  width: 80%;
  line-height: 1.3;
  text-shadow: 0 0 5px silver;
}

.card:hover .front {
  transform: rotateY(180deg);
}

.card:hover .back {
  transform: rotateY(360deg);
}

@media (max-width: 1000px) {
  .card-overlay {
    padding-inline: min(180px, 5%);
  }
}

@media (max-width: 725px) {
  .canvas-div {
    animation-duration: 15s;
  }
}

@media (max-width: 300px) {
  .canvas-div h1 {
    font-size: 2.5rem;
  }
}
