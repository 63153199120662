.cb-over {
  position: fixed;
  bottom: 0;
  right: 0%;
  width: 250px;
  height: 250px;
  z-index: 999;
  user-select: none;
}

.contact-button {
  position: absolute;
  bottom: 5vh;
  right: 5vw;
  width: fit-content;
  height: auto;

  background-color: rgba(3, 101, 73, 0.8);

  color: var(--white);
  z-index: 999;
  padding: 1rem 2rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: clamp(var(--clamp-xs-text));
  font-weight: bold;
  /*   writing-mode: vertical-rl;
 */
  text-transform: uppercase;
  box-shadow: 0 0 32px;
  transition: var(--transition-short);
  text-align: center;
  animation: colourful 1s ease-out infinite alternate;
}

@keyframes colourful {
  to {
    transform: scale(1.1);
  }
}

.contact-button:hover {
  background-color: rgba(216, 32, 32, 0.85);
}
