.contact {
  width: 100%;
  background-image: none;
}

.headquarters-img {
  width: 100%;
  padding-inline: var(--padding-inline-ten-percent);
  height: 500px;
  object-fit: cover;
  object-position: bottom;
  border-radius: var(--border-radius);
  filter: drop-shadow(var(--drop-shadow-intro-images));
  filter: drop-shadow(var(--drop-shadow-lighter));
  user-select: none;
  margin-top: var(--gap);
}
